@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .custom-gradient {
    background: linear-gradient(to top left, #cd55f9, #a159f2, #6e5de9),
      linear-gradient(to bottom right, #6e5de9 0%, #a159f2);
    background-blend-mode: overlay;
  }

  .hover-gradient {
      border: 1px solid;
      border-image: linear-gradient(to right, #6e5de9, #a159f2, #cd55f9) 2;
    }

    
}
