/* Custom rectangular pagination bullets */
.swiper-pagination-bullet {
  width: 20px;            /* Rectangle width */
  height: 2px;            /* Rectangle height */
  border-radius: 4px;     /* Slightly rounded corners */
  background-color: #888; /* Default bullet color */
  opacity: 0.7;           /* Transparency */
  transition: background-color 0.3s ease;
}

.swiper-pagination-bullet-active {
  background-color: #6E5DE9; /* Active bullet color */
  opacity: 1;
}

.mySwiper .swiper-pagination {
  position: relative; /* Move it out of the default absolute positioning */
  margin-top: 16px;   /* Add space between slides and pagination */
  bottom: auto;       /* Reset bottom positioning */
}